<template>
  <TableWidgetStyled>
    <TableV2
      :title="$tc('widgetTypes.CYCLE_TABLE')"
      :headers="headers"
      :rows="rowsWithPagination"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="isLoading"
      :searchQuery="searchQuery"
      :enableSearch="true"
      :isMobile="isMobile"
      :enableFooter="true"
      :messages="messages"
      :showLoadMore="showLoadMore"
      :totalRowCount="assetCycles.length"
      @tableExport="tableExport"
      @textFilter="textSearch"
      @load-more-rows="loadMore"
    />
  </TableWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import { compact, orderBy, partition, get, concat, isEmpty } from 'lodash'
import { TimeframeMixin } from '@common/mixins'
import localesMixin from '@/mixins/locales'
import resizeMixin from '@/mixins/resize'
import { formatDateAccordingToSettings } from '@/utils/time'
import units, { convertToLocalUnitSystem } from '@/utils/units'
import { exportTableDataToCSV } from '@/utils/export'
import { TABLE_SORT_TYPES } from '@/constants/settings'
import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import fullTimeDurationFormatter from '@/utils/duration'
import assetDimensionWithDurationFormat from '@/utils/widgets/assetDimensionWithDurationFormat'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

import { useAssetStore } from '@/stores/assets'

import ADDITIONAL_ATTRIBUTES from '@/components/Widgets/CycleTable/cycleTableAdditionalAttributes'
import CYCLE_TABLE_ASSETDIMENSIONS from '@/components/Widgets/CycleTable/cycleTableAssetDimensions'
import ASSET_CYCLES_QUERY from '#/graphql/operations/asset/cycles.gql'

export const TableWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.navFontNormal};
`
export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  inject: ['uiSettings'],
  mixins: [TimeframeMixin, localesMixin, resizeMixin],
  props: {
    title: {
      type: String,
    },
    widget: {
      type: Object,
      required: false,
    },
  },
  components: {
    TableWidgetStyled,
    TableV2,
  },
  data() {
    return {
      assetCycles: [],
      page: 0,
      additionalAttributes: ADDITIONAL_ATTRIBUTES,
      isMobile: true,
      searchQuery: '',
      sorting: {
        key: 'asset',
        asc: true,
      },
      filterOptionsSelected: [],
      filtersAvailable: [],
      batchSize: 100, // Number of items to load initially
      loadedBatch: 0,
      totalCount: 0,
    }
  },
  watch: {
    selectedWidgetSettings: {
      handler(selectedWidgetSettings) {
        const hasStart = selectedWidgetSettings.find(f => f.label === 'start')
        if (hasStart) {
          this.sorting = {
            key: 'start',
            asc: true,
          }
        } else {
          this.sorting = {
            key: 'asset',
            asc: true,
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    assetsSelected() {
      return this.assetStore.assetsSelected()
    },
    assetIds() {
      return this.assetsSelected.map(({ id }) => id)
    },
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    hasDecimalFormatEnabled() {
      return this.widget?.widgetSettings?.hasDecimalFormatEnabled
    },
    isLoading() {
      return this.$apollo.loading
    },
    dimensionsSelected() {
      const dimensions = get(this.widget, 'dimensions', [])
      const sortedDimensions = dimensions.sort((a, b) => {
        return a.position - b.position
      })
      return sortedDimensions.map(({ assetDimension }) => CYCLE_TABLE_ASSETDIMENSIONS[assetDimension.name])
    },
    selectedWidgetSettings() {
      const widgetSettings = { ...get(this.widget, 'widgetSettings', {}) }
      let selectedSettings = isEmpty(widgetSettings)
        ? this.additionalAttributes
        : this.additionalAttributes.filter(({ id }) => widgetSettings[id] === true)

      delete widgetSettings['id']
      delete widgetSettings['__typename']
      let count = 0
      return selectedSettings.map(setting => {
        const label = this.$t('cycleTable.' + setting.label)
        const sortType = setting.label === 'start' || setting.label === 'end' ? TABLE_SORT_TYPES.NEW_TO_OLD : TABLE_SORT_TYPES.A_TO_Z

        count = count + 1
        return {
          ...setting,
          label,
          name: setting.label,
          id: `header_${setting.id}`,
          sortType,
          isSortable: true,
          unit: '',
          sortKey: `${setting.label}`,
          size: 'medium',
        }
      })
    },
    headers() {
      let count = 0
      const dimensions = get(this.widget, 'dimensions', []).map(dimension => {
        const { assetDimension } = dimension
        const { physicalUnitSI, physicalUnitUIMetric, physicalUnitUIImperial, name, nameTranslations } = assetDimension

        const unitUI = this.selectedUIUnitSystem === 'IMPERIAL' && physicalUnitUIImperial ? physicalUnitUIImperial : physicalUnitUIMetric

        let unit = convertToLocalUnitSystem(this.selectedUIUnitSystem, physicalUnitUIMetric, physicalUnitUIImperial) || physicalUnitSI
        unit = unit ? unit.replace('mt', 't') : ''

        const label = nameTranslations ? getAssetDimensionNameByLocale(nameTranslations, this.locale) : name

        return {
          ...assetDimension,
          name: CYCLE_TABLE_ASSETDIMENSIONS[name],
          label,
          id: `header_${name}`,
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: `dimensions[${count++}].value`,
          isDimension: true,
          unit,
          unitUI,
          physicalUnitSI,
          size: 'medium',
        }
      })

      return [...this.selectedWidgetSettings, ...dimensions]
    },
    assetMap() {
      return Object.fromEntries(this.assetsSelected.map(asset => [asset.id, asset]))
    },
    assetsCyclesSorted() {
      const partitions = partition(this.assetCycles, x => !!get(x, this.sorting.key, null))
      const sortDirection =
        this.sorting.key === 'start' || this.sorting.key === 'end' ? (this.sorting.asc ? 'desc' : 'asc') : this.sorting.asc ? 'asc' : 'desc'
      const sortedPartitions = concat(orderBy(partitions[0], [this.sorting.key], [sortDirection]), partitions[1])
      const result = concat(...sortedPartitions)
      this.resetBatch()
      return result
    },
    rows() {
      const rows = this.assetsCyclesSorted.map((cycle, i) => {
        const cells = this.headers.map(attribute => {
          const isDimension = get(attribute, 'isDimension', false)
          const attributeName = attribute.name

          let data = cycle[attributeName]
          let dateTime = null

          if (!isDimension) {
            if (attribute.isDateTime) {
              dateTime = DateTime.fromISO(data)
              data = formatDateAccordingToSettings(data, this.uiSettings, this.selectedTimezone)
            }

            if (['sourceAssetTalpaID', 'targetAssetTalpaID', 'asset'].includes(attributeName)) {
              data = get(this.assetMap, [cycle[attributeName], 'name'], cycle[attributeName])
            }

            return {
              id: `${cycle.cycleID}_${attribute.label}`,
              sortableValue: data,
              isMobilePrimarycol: attributeName === 'asset',
              mobilePrimarycolLabel: attributeName === 'asset' ? data : undefined,
              headerId: `${attribute.id}`,
              isDateTime: attribute.isDateTime || false,
              dateTime,
            }
          } else {
            const dimension = cycle.dimensions.find(({ name }) => name === attributeName)
            const value = dimension?.value ?? null
            const defaultDimensionName = Object.keys(CYCLE_TABLE_ASSETDIMENSIONS).find(
              key => CYCLE_TABLE_ASSETDIMENSIONS[key] === dimension?.name,
            )
            const isDurationFormatAvailable = assetDimensionWithDurationFormat.includes(defaultDimensionName)
            const data =
              value !== null
                ? attribute.physicalUnitSI === 's' && !this.hasDecimalFormatEnabled && isDurationFormatAvailable
                  ? fullTimeDurationFormatter(value)
                  : units(
                      value,
                      attribute.physicalUnitSI,
                      attribute.unitUI,
                      2,
                      false,
                      true,
                      false,
                      this.thousandsSeperator,
                      this.decimalSeperator,
                      true,
                    )
                : '-'

            return {
              id: `${cycle.cycleID}_${attribute.label}`,
              sortableValue: data,
              headerId: `${attribute.id}`,
              isDateTime: false,
              dateTime: null,
            }
          }
        })

        return {
          id: `row_${cycle.cycleID}_${i}`,
          cells,
        }
      })

      const filteredTable = rows.filter(row => {
        const props = compact(row.cells.map(item => item.sortableValue))
        return props.some(
          prop =>
            !this.searchQuery ||
            (typeof prop === 'string'
              ? prop.toLowerCase().includes(this.searchQuery.toLowerCase())
              : prop.toString(10).includes(this.searchQuery)),
        )
      })

      this.setCounter(filteredTable.length)
      return filteredTable
    },
    rowsWithPagination() {
      return this.rows.slice(0, this.batchSize * (this.loadedBatch + 1))
    },
    messages() {
      const dimensionNames = this.dimensionsSelected
        .map(dimension =>
          dimension.nameTranslations ? getAssetDimensionNameByLocale(dimension.nameTranslations, this.locale) : dimension.name,
        )
        ?.toString()
      let message = ''
      if (this.rows.length === 0) {
        message =
          this.searchQuery === ''
            ? this.$t('messages.noDimensionData', { dimension: dimensionNames?.split(',').join(', ') })
            : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
    showLoadMore() {
      return this.rowsWithPagination.length < this.totalCount
    },
  },
  methods: {
    loadMore() {
      this.loadedBatch++
    },
    setCounter(count) {
      this.totalCount = count
    },
    resetBatch() {
      this.loadedBatch = 0
    },
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        const isMobile = size < 600 && size >= 96
        this.isMobile = isMobile || (this.isPreview && this.isMobilePreview)
      })
    },
    tableExport(type) {
      if (type === 'csv') {
        const mappedHeaders = this.headers.map(header => ({ data: header.name }))
        const mappedRows = this.rows.flatMap(header => ({ data: header.cells })).flatMap(item => item.data)
        exportTableDataToCSV(mappedHeaders, mappedRows, 'talpa-export_cycle_table')
      }
    },
    textSearch(query) {
      this.searchQuery = query
      this.resetBatch()
    },
  },
  apollo: {
    assetCycles: {
      query: ASSET_CYCLES_QUERY,
      variables() {
        return {
          where: {
            assets: {
              id_in: this.assetIds,
            },
            timeframe: this.selectedTimeframeParam,
            dimensions: this.dimensionsSelected,
          },
        }
      },
      skip() {
        return !this.selectedTimeframeParam || this.assetIds.length < 1 || this.dimensionsSelected.length < 1
      },
    },
  },
}
</script>
