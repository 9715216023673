export default {
  utilization: 'utilization',
  average_loading_duration: 'loading_duration',
  average_dumping_duration: 'unloading_duration',
  distance_loaded: 'distance_loaded',
  distance_unloaded: 'distance_unloaded',
  working_time_driving_loaded: 'driving_time_loaded',
  working_time_driving_unloaded: 'driving_time_unloaded',
  idle_time_loaded: 'idle_time_loaded',
  idle_time_unloaded: 'idle_time_unloaded',
  liters_per_tonne: 'fuel_per_tonnage',
  tonne_kilometers: 'tonnage_kilometer',
  avg_utilization_payload_capacity: 'utilization_payload_capacity',
  working_time: 'productive_time',
  tonnage: 'tonnage',
  engine_hours: 'max_cum_operation_time',
  passes_per_truck: 'no_buckets',
  cycle_duration_sum: 'duration',
  sum_altitude_loaded: 'sum_altitude_up_loaded',
  idle_time: 'idle_time',
  avg_speed: 'avg_speed',
  no_drainage: 'no_drainage',
  no_drills: 'no_drills',
  no_bolts: 'no_bolts',
  min_speed: 'min_speed',
  altitude_up: 'sum_altitude_up',
  altitude_down: 'sum_altitude_down',
  distance: 'sum_distance',
  max_speed: 'max_speed',
  fuel_consumption: 'sum_fuel',
  short_drills: 'short_drills',
  long_drills: 'long_drills',
  net_energy_consumption: 'sum_energy_consumption',
  energy_consumption_per_tonnage: 'energy_consumption_per_tonnage',
  gross_vehicle_weight: 'gross_vehicle_weight',
  tkph: 'tkph',
  carryback: 'carryback',
  tonnage_per_pass: 'tonnage',
  drilled_length_sum: 'sum_drilled_length',
  drilled_length_avg: 'avg_drilled_length',
  drilled_depth_sum: 'avg_drilled_depth',
  drilled_depth_avg: 'sum_drilled_depth',
  drill_time_sum: 'sum_drill_time',
  drill_time_hole_avg: 'avg_drill_time_per_hole',
  bolt_time_sum: 'sum_bolt_time',
  bolt_time_avg: 'avg_bolt_time_per_hole',
  hydraulic_idle_time_sum: 'sum_hydraulic_idle_time',
  diesel_idle_time_sum: 'sum_diesel_idle_time',
  electrical_operating_time_sum: 'sum_electrical_operating_time',
  diesel_operating_time_by_activity: 'sum_diesel_operating_time',
  average_speed_loaded: 'avg_speed_loaded',
  average_speed_unloaded: 'avg_speed_unloaded',
}
