export default [
  { id: 'assetColumnShown', label: 'asset', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'cycleIDColumnShown', label: 'cycleID', unit: null, isDateTime: false, isNumber: true, isText: false },
  { id: 'startColumnShown', label: 'start', unit: null, isDateTime: true, isNumber: true, isText: false },
  { id: 'endColumnShown', label: 'end', unit: null, isDateTime: true, isNumber: true, isText: false },
  { id: 'sourceAssetTalpaIDColumnShown', label: 'sourceAssetTalpaID', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'sourceGeofenceColumnShown', label: 'sourceGeofence', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'materialTypeColumnShown', label: 'materialType', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'targetAssetTalpaIDColumnShown', label: 'targetAssetTalpaID', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'targetGeofenceColumnShown', label: 'targetGeofence', unit: null, isDateTime: false, isNumber: false, isText: true },
]
